exports.obj = function (username, appId, token) {
    const schemaObj = {};
    schemaObj[`/${username}/${appId}/${token}/search`] = {
        post: {
            tags: ['Claude AI'],
            summary: 'Send your question prompt to NocodeAPI',
            description: 'Send your question prompt to your NocodeAPI.',
            produces: ['application/json'],
            parameters: [
                {
                    name: 'body',
                    in: 'body',
                    description: 'Pass question prompt into body',
                    required: true,
                    type: 'string',
                    sampleData: {
                        prompt: 'Explain how to create a youtube video in simple terms.',
                    },
                },
            ],
            responses: {
                200: {
                    description: 'successful operation',
                },
            },
        },
        get: {
            tags: ['Claude AI'],
            summary: 'Generate Claude AI Prompt',
            description: 'Generate Claude AI Prompt',
            produces: ['application/json'],
            parameters: [
                {
                    name: 'Prompt',
                    in: 'prompt',
                    description: 'Generate Claude AI Prompts',
                    required: true,
                    type: 'string',
                },
            ],
            responses: {
                200: {
                    description: 'successful operation',
                },
            },
        },
    };
    return schemaObj;
};
