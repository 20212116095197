export const formObj = {
  airtable: {
    name: 'airtable',
    encrypt: 'pat_key',
    authType: 'key',
    dockLink:
      'https://nocodeapi.com/video/setup-airtable-api-without-any-backend-coding-and-server-no-code-api',
    fields: [
      {
        label: 'Give a name',
        name: 'name',
        rules: [{ required: true, message: 'Name is required!' }],
        type: 'input',
        instructions: 'Give a name to this API.',
      },
      {
        label: 'API Key',
        label: 'API Key (Deprecated)',
        rules: [
          {
            required: false,
            message: 'API key of your Airtable!',
          },
        ],
        readOnly: true,
        type: 'inputPwd',
        instructions:
          'You can get your Airtable API key here <a href="https://airtable.com/account" target="_blank">https://airtable.com/account</a>',
      },
      {
        label: 'Personal Access Token',
        name: 'pat_key',
        rules: [
          {
            required: true,
            message: 'Personal access token of your Airtable!',
          },
        ],
        type: 'inputPwd',
        instructions:
          'You can get your Airtable Personal Access Token here <a href="https://airtable.com/account" target="_blank">https://airtable.com/account</a>',
      },
      {
        label: 'Base Id',
        name: 'base_id',
        rules: [
          {
            required: true,
            message: 'Base Id of your airtable form',
          },
        ],
        type: 'input',
        instructions:
          'Go here <a href="https://support.airtable.com/docs/finding-airtable-ids" target="_blank" rel="noopener noreferrer"> https://support.airtable.com/docs/finding-airtable-ids </a> to find your base ID in URL & It begins with <b>app</b>. <br /> ex: https://airtable.com/<b style={{ color: "green" }}>appofMoPxdu7iQ7hf</b>',
      },
    ],
  },
  algolia: {
    name: 'algolia',
    authType: 'key',
    encrypt: 'admin_api_key',
    dockLink: 'https://nocodeapi.com/docs/algolia-api',
    fields: [
      {
        label: 'Give a name',
        name: 'name',
        rules: [{ required: true, message: 'Name is required!' }],
        type: 'input',
        instructions: 'Give a name to this API.',
      },
      {
        label: 'Admin API Key',
        name: 'admin_api_key',
        rules: [
          {
            required: true,
            message: 'Admin API Key is required!',
          },
        ],
        type: 'inputPwd',
        instructions:
          'You can find Admin API Key ↳ <a href="https://www.algolia.com/api-keys" target="_blank" rel="noopener noreferrer">Algolia account</a>.',
      },
      {
        label: 'Application Id',
        name: 'application_id',
        rules: [
          {
            required: true,
            message: 'Application Id is required.',
          },
        ],
        type: 'input',
        instructions:
          'You can find your Application Id ↳ <a href="https://www.algolia.com/api-keys" target="_blank" rel="noopener noreferrer">Algolia account</a>.',
      },
    ],
  },
  calendly: {
    name: 'Calendly',
    authType: 'oauth',
    authKey: 'calndelyEnable',
    authEndpoint: `${process.env.GATSBY_BASE_URL}/auth/calendly`,
    encrypt: 'refresh_token',
    dockLink: 'https://nocodeapi.com/video/setup-calendly-api-without-coding-api-nocode',
    fields: [
      {
        label: 'Give a name',
        name: 'name',
        rules: [{ required: true, message: 'Name is required!' }],
        type: 'input',
        instructions: 'Give a name to this API.',
      },
    ],
  },
  cloudinary: {
    name: 'Cloudinary',
    authType: 'key',
    encrypt: 'api_secret',
    dockLink: 'https://nocodeapi.com/cloudinary-image-api',
    fields: [
      {
        label: 'Give a name',
        name: 'name',
        rules: [{ required: true, message: 'Name is required!' }],
        type: 'input',
        instructions: 'Give a name to this API.',
      },
      {
        label: 'Cloud Name',
        name: 'cloud_name',
        rules: [
          {
            required: true,
            message: 'Cloud Name is required!',
          },
        ],
        type: 'input',
        instructions:
          'You can find your Cloud Name ↳ <a href="https://cloudinary.com/console" target="_blank" rel="noopener noreferrer">https://cloudinary.com/console</a>',
      },
      {
        label: 'API key',
        name: 'api_key',
        rules: [
          {
            required: true,
            message: 'API Key is required!',
          },
        ],
        type: 'inputPwd',
        instructions:
          'You can find your API Key ↳ <a href="https://cloudinary.com/console" target="_blank" rel="noopener noreferrer">https://cloudinary.com/console</a>',
      },
      {
        label: 'API Secret',
        name: 'api_secret',
        rules: [
          {
            required: true,
            message: 'API Secret is required.',
          },
        ],
        type: 'inputPwd',
        instructions:
          'You can find your API Secret ↳ <a href="https://cloudinary.com/console" target="_blank" rel="noopener noreferrer">https://cloudinary.com/console</a>',
      },
    ],
  },
  contentful: {
    name: 'Contentful',
    authType: 'key',
    encrypt: 'access_token',
    dockLink: null,
    fields: [
      {
        label: 'Give a name',
        name: 'name',
        rules: [{ required: true, message: 'Name is required!' }],
        type: 'input',
        instructions: 'Give a name to this API.',
      },
      {
        label: 'Space Id',
        name: 'space_id',
        rules: [
          {
            required: true,
            message: 'Space Id is required!',
          },
        ],
        type: 'input',
        instructions: 'Your contentful <b>space ID</b>',
      },
      {
        label: 'Access Token',
        name: 'access_token',
        rules: [
          {
            required: true,
            message: 'Access Token is required!',
          },
        ],
        type: 'inputPwd',
        instructions:
          'To find Access Token login and go to <b>Space Settings</b> -> <b>API Keys</b>',
      },
    ],
  },
  cx: {
    name: 'Currency Exchange',
    authType: 'key',
    encrypt: 'none',
    dockLink: null,
    fields: [
      {
        label: 'Give a name',
        name: 'name',
        rules: [{ required: true, message: 'Name is required!' }],
        type: 'input',
        instructions: 'Give a name to this API.',
      },
    ],
  },
  ep: {
    name: 'Endpoint Proxy',
    authType: 'key',
    encrypt: 'none',
    dockLink: 'https://nocodeapi.com/video/setup-api-proxy-without-coding-api-proxy',
    fields: [
      {
        label: 'Give a name',
        name: 'name',
        rules: [{ required: true, message: 'Name is required!' }],
        type: 'input',
        instructions: 'Give a name to this API.',
      },
      {
        label: 'Endpoint',
        name: 'endpoint',
        rules: [
          {
            type: 'url',
            required: true,
            message: 'Endpoint is required!',
          },
        ],
        type: 'input',
        instructions: 'Endpoint URL that you wants to convert into proxy.',
      },
      {
        label: 'Header Object',
        name: 'headerObj',
        type: 'jsonInput',
        instructions: 'Header objects key value, if this is required',
      },
    ],
  },
  hubspot: {
    name: 'HubSpot',
    authType: 'key',
    encrypt: 'api_key',
    dockLink: 'https://nocodeapi.com/hubspot-api',
    fields: [
      {
        label: 'Give a name',
        name: 'name',
        rules: [{ required: true, message: 'Name is required!' }],
        type: 'input',
        instructions: 'Give a name to this API.',
      },
      {
        label: `Private App's Access Token`,
        name: 'api_key',
        rules: [
          {
            required: true,
            message: 'Access Token is required!',
          },
        ],
        type: 'inputPwd',
        instructions: `Login into your HubSpot Account. <b>Profile & Preferences</b> -> <b>Integrations</b> -> <b>Private Apps</b> -> <b>Access Token</b> 
                           <br/>
                           Create a new Private app and select all required scopes you will use.
                           For Official documentation <a href="https://developers.hubspot.com/docs/api/private-apps" target="_blank">click here</a>.
                          `,
      },
    ],
  },
  shopify: {
    name: 'Shopify',
    authType: 'key',
    encrypt: 'api_key',
    dockLink: 'https://nocodeapi.com/shopify-api',
    fields: [
      {
        label: 'Give a name',
        name: 'name',
        rules: [{ required: true, message: 'Name is required!' }],
        type: 'input',
        instructions: 'Give a name to this API.',
      },
      {
        label: 'Store username',
        name: 'store_id',
        rules: [
          {
            required: true,
            message: 'Store username is required!',
          },
        ],
        type: 'input',
        instructions:
          'You can get this from your shop url. example-> https://<b>shop_name</b>.myshopify.com shop_name is username',
      },
      {
        label: 'Admin API access token',
        name: 'api_key',
        rules: [
          {
            required: true,
            message: 'Admin API access token is required!',
          },
        ],
        type: 'inputPwd',
        instructions:
          'Login into your Shopify Store. <b>Apps</b> Scroll down -> <b>Develop apps for your store</b> -> <b>Create an app</b> -> <b>Configure Admin API Scopes</b> Select the scopes you want to use -> <b>Install app</b> -> <b>Admin API access token</b>',
      },
    ],
  },
  ghost: {
    name: 'Ghost',
    authType: 'key',
    encrypt: 'api_key',
    dockLink: 'https://nocodeapi.com/ghost-cms-api',
    fields: [
      {
        label: 'Give a name',
        name: 'name',
        rules: [{ required: true, message: 'Name is required!' }],
        type: 'input',
        instructions: 'Give a name to this API.',
      },
      {
        label: 'API key',
        name: 'api_key',
        rules: [
          {
            required: true,
            message: 'API key is required!',
          },
        ],
        type: 'inputPwd',
        instructions:
          'Login into your Ghost CMS. <b>Settings</b> -> <b>Integrations</b> -> <b>Make Custom integration</b>',
      },
      {
        label: 'API URL',
        name: 'api_url',
        rules: [
          {
            required: true,
            message: 'API URL is required!',
          },
        ],
        type: 'input',
        instructions: '<b>API URL</b> of your Ghost CMS.',
      },
    ],
  },
  github: {
    name: 'GitHub',
    authType: 'oauth',
    authKey: 'githubEnable',
    authEndpoint: `${process.env.GATSBY_BASE_URL}/auth/github`,
    encrypt: 'access_token',
    dockLink:
      'https://nocodeapi.com/video/setup-git-hub-api-without-coding-and-server-github-api-nocode',
    fields: [
      {
        label: 'Give a name',
        name: 'name',
        rules: [{ required: true, message: 'Name is required!' }],
        type: 'input',
        instructions: 'Give a name to this API.',
      },
    ],
  },
  typeform: {
    name: 'Typeform',
    authType: 'key',
    encrypt: 'access_token',
    dockLink: null,
    fields: [
      {
        label: 'Give a name',
        name: 'name',
        rules: [{ required: true, message: 'Name is required!' }],
        type: 'input',
        instructions: 'Give a name to this API.',
      },
      {
        label: 'Personal access token',
        name: 'access_token',
        rules: [
          {
            required: true,
            message: 'Personal token is required!',
          },
        ],
        type: 'inputPwd',
        instructions: 'Go to your account and get the <b>Personal token</b>.',
      },
    ],
  },
  mailerlite: {
    name: 'Mailerlite',
    authType: 'key',
    encrypt: 'api_key',
    dockLink: 'https://nocodeapi.com/mailerlite-api',
    fields: [
      {
        label: 'Give a name',
        name: 'name',
        rules: [{ required: true, message: 'Name is required!' }],
        type: 'input',
        instructions: 'Give a name to this API.',
      },
      {
        label: 'API key',
        name: 'api_key',
        rules: [
          {
            required: true,
            message: 'API key is required!',
          },
        ],
        type: 'inputPwd',
        instructions:
          'You can get your Mailerlite API key here ↳ <a href="https://app.mailerlite.com/integrations/api/" target="_blank">https://app.mailerlite.com/integrations/api/</a>',
      },
    ],
  },
  twilio: {
    name: 'Twilio',
    authType: 'key',
    encrypt: 'auth_token',
    dockLink: 'https://nocodeapi.com/twilio-api',
    fields: [
      {
        label: 'Give a name',
        name: 'name',
        rules: [{ required: true, message: 'Name is required!' }],
        type: 'input',
        instructions: 'Give a name to this API.',
      },
      {
        label: 'Account SID',
        name: 'account_sid',
        rules: [
          {
            required: true,
            message: 'Account SID is required!',
          },
        ],
        type: 'inputPwd',
        instructions: 'Login to your twilio account and you can find Token & SID there.',
      },
      {
        label: 'Auth Token',
        name: 'auth_token',
        rules: [
          {
            required: true,
            message: 'Auth Token is required!',
          },
        ],
        type: 'inputPwd',
        instructions: 'Login to your twilio account and you can find Token & SID there.',
      },
    ],
  },
  snipcart: {
    name: 'Snipcart',
    authType: 'key',
    encrypt: 'secret_key',
    dockLink: 'https://nocodeapi.com/snipcart-api',
    fields: [
      {
        label: 'Give a name',
        name: 'name',
        rules: [{ required: true, message: 'Name is required!' }],
        type: 'input',
        instructions: 'Give a name to this API.',
      },
      {
        label: 'Secret API key',
        name: 'secret_key',
        rules: [
          {
            required: true,
            message: 'Secret API is required!',
          },
        ],
        type: 'inputPwd',
        instructions:
          'You can find your key here <a href="https://app.snipcart.com/dashboard/account/credentials" target="_blank">https://app.snipcart.com/dashboard/account/credentials</a>',
      },
    ],
  },
  ga: {
    name: 'Google Analytics',
    authType: 'oauth',
    authKey: 'gaEnable',
    authEndpoint: `${process.env.GATSBY_BASE_URL}/auth/google/analytics`,
    encrypt: 'refresh_token',
    dockLink:
      'https://nocodeapi.com/video/setup-google-analytics-data-with-no-code-api-analytics-google-api',
    fields: [
      {
        label: 'Give a name',
        name: 'name',
        rules: [{ required: true, message: 'Name is required!' }],
        type: 'input',
        instructions: 'Give a name to this API.',
      },
      {
        label: 'View ID required for GA3',
        name: 'view_id',
        rules: [
          {
            required: false,
            message: 'View ID is required if you are using GA3 or Universal Analytics!',
          },
        ],
        type: 'input',
        instructions: 'View ID from View Settings, it is only required if you are using GA3 or Universal Analytics! ',
      },
      {
        label: 'Property ID required for GA4',
        name: 'property_id',
        rules: [
          {
            required: false,
            message: 'Property ID',
          },
        ],
        type: 'input',
        instructions: "Once you've selected or created a GA4 property, you can find the property ID.Click on the Admin option again. In the `Property` column, click on `Property Settings`Under the Property Information section, you will see the Measurement ID. This ID is your GA4 property ID. This is required in case of GA4"
      },
    ],
  },
  google_sheets: {
    name: 'Google Sheet',
    formType: 'dynamicForm',
    authType: 'oauth',
    authKey: 'sheetEnable',
    authEndpoint: `${process.env.GATSBY_BASE_URL}/auth/google/sheet`,
    encrypt: 'refresh_token',
    dockLink:
      'https://nocodeapi.com/video/convert-google-sheet-into-json-api-without-coding-sheet-google-api',
    fields: [
      {
        label: 'Give a name',
        name: 'name',
        rules: [{ required: true, message: 'Name is required!' }],
        type: 'input',
        instructions: 'Give a name to this API.',
      },
      {
        label: 'Sheet Id',
        name: 'sheet_id',
        rules: [
          {
            required: true,
            message: 'Sheet Id is required!',
          },
        ],
        type: 'input',
        instructions:
          "The Sheet id is the part after d/, in the below <b>example link</b>, it's 1mkabaGFBmlXENl5b-HLVRRCNU8Tx_y3Pe_gnyIREX1I-hDqQ, https://docs.google.com/spreadsheets/d/<b>1mkabaGFBmlXENl5b-HLVRRCNU8Tx_y3Pe_gnyIREX1I</b>/edit.",
      },
    ],
  },
  excel_sheets: {
    name: 'Excel Sheet',
    formType: 'dynamicForm',
    authType: 'oauth',
    authKey: 'excelEnable',
    authEndpoint: `${process.env.GATSBY_BASE_URL}/auth/azure/excel`,
    encrypt: 'refresh_token',
    dockLink: '',
    fields: [
      {
        label: 'Give a name',
        name: 'name',
        rules: [{ required: true, message: 'Name is required!' }],
        type: 'input',
        instructions: 'Give a name to this API.',
      },
      {
        label: 'Sheet Id',
        name: 'sheet_id',
        rules: [
          {
            required: true,
            message: 'Sheet Id is required!',
          },
        ],
        type: 'input',
        instructions: '',
      },
    ],
  },
  fit: {
    name: 'Google fit',
    authType: 'oauth',
    authKey: 'fitEnable',
    authEndpoint: `${process.env.GATSBY_BASE_URL}/auth/google/fit`,
    encrypt: 'refresh_token',
    dockLink: null,
    fields: [
      {
        label: 'Give a name',
        name: 'name',
        rules: [{ required: true, message: 'Name is required!' }],
        type: 'input',
        instructions: 'Give a name to this API.',
      },
    ],
  },
  drive: {
    name: 'Google Drive',
    authType: 'oauth',
    authKey: 'driveEnable',
    authEndpoint: `${process.env.GATSBY_BASE_URL}/auth/google/drive`,
    encrypt: 'refresh_token',
    dockLink: null,
    fields: [
      {
        label: 'Give a name',
        name: 'name',
        rules: [{ required: true, message: 'Name is required!' }],
        type: 'input',
        instructions: 'Give a name to this API.',
      },
    ],
  },
  bigquery: {
    name: 'Google Big Query',
    authType: 'oauth',
    authKey: 'bigqueryEnable',
    authEndpoint: `${process.env.GATSBY_BASE_URL}/auth/google/bigquery`,
    encrypt: 'refresh_token',
    dockLink: null,
    fields: [
      {
        label: 'Give a name',
        name: 'name',
        rules: [{ required: true, message: 'Name is required!' }],
        type: 'input',
        instructions: 'Give a name to this API.',
      },
    ],
  },
  bigtable: {
    name: 'Google Big Table',
    authType: 'oauth',
    authKey: 'bigtableEnable',
    authEndpoint: `${process.env.GATSBY_BASE_URL}/auth/google/bigtable`,
    encrypt: 'refresh_token',
    dockLink: null,
    fields: [
      {
        label: 'Give a name',
        name: 'name',
        rules: [{ required: true, message: 'Name is required!' }],
        type: 'input',
        instructions: 'Give a name to this API.',
      },
    ],
  },
  calendar: {
    name: 'Google Calendar',
    authType: 'oauth',
    authKey: 'calendarEnable',
    authEndpoint: `${process.env.GATSBY_BASE_URL}/auth/google/calendar`,
    encrypt: 'refresh_token',
    dockLink: null,
    fields: [
      {
        label: 'Give a name',
        name: 'name',
        rules: [{ required: true, message: 'Name is required!' }],
        type: 'input',
        instructions: 'Give a name to this API.',
      },
    ],
  },
  webmaster: {
    name: 'Google Search Console',
    authType: 'oauth',
    authKey: 'webmasterEnable',
    authEndpoint: `${process.env.GATSBY_BASE_URL}/auth/google/webmaster`,
    encrypt: 'refresh_token',
    dockLink: null,
    fields: [
      {
        label: 'Give a name',
        name: 'name',
        rules: [{ required: true, message: 'Name is required!' }],
        type: 'input',
        instructions: 'Give a name to this API.',
      },
    ],
  },
  gr: {
    name: 'Goodreads',
    authType: 'key',
    encrypt: 'api_key',
    dockLink: 'https://nocodeapi.com/video/goodreads-books-json-api-without-code',
    fields: [
      {
        label: 'Give a name',
        name: 'name',
        rules: [{ required: true, message: 'Name is required!' }],
        type: 'input',
        instructions: 'Give a name to this API.',
      },
      {
        label: 'API key',
        name: 'api_key',
        rules: [
          {
            required: true,
            message: 'API key is required!',
          },
        ],
        type: 'inputPwd',
        instructions:
          'Here you can find your Goodreads API key <a href="https://www.goodreads.com/api/keys" target="_blank" rel="noopener noreferrer"> https://www.goodreads.com/api/keys</a>',
      },
    ],
  },
  instagram: {
    name: 'Instagram',
    authType: 'key',
    encrypt: 'access_token',
    dockLink: 'https://nocodeapi.com/video/generate-instagram-long-lived-access-token',
    fields: [
      {
        label: 'Give a name',
        name: 'name',
        rules: [{ required: true, message: 'Name is required!' }],
        type: 'input',
        instructions: 'Give a name to this API.',
      },
      {
        label: 'Long Lived Access Token',
        name: 'access_token',
        rules: [
          {
            required: true,
            message: 'Access token is required!',
          },
        ],
        type: 'inputPwd',
        instructions:
          '<div className="subtitle">Go here <a href="https://developers.facebook.com/apps/" target="_blank" rel="noopener noreferrer">https://developers.facebook.com/apps/</a> & Create an App to get Long Lived Access token Or, Watch Video Tutorial here <a href="https://nocodeapi.com/video/generate-instagram-long-lived-access-token" target="_blank" rel="noopener noreferrer">Instagram Long Lived Token</a></div>',
      },
    ],
  },
  link_preview: {
    name: 'Link Preview',
    authType: 'key',
    encrypt: 'none',
    dockLink: null,
    fields: [
      {
        label: 'Give a name',
        name: 'name',
        rules: [{ required: true, message: 'Name is required!' }],
        type: 'input',
        instructions: 'Give a name to this API.',
      },
    ],
  },
  pdf: {
    name: 'HTML to PDF',
    authType: 'key',
    encrypt: 'none',
    dockLink: null,
    fields: [
      {
        label: 'Give a name',
        name: 'name',
        rules: [{ required: true, message: 'Name is required!' }],
        type: 'input',
        instructions: 'Give a name to this API.',
      },
    ],
  },
  screen: {
    name: 'Website Screenshot',
    authType: 'key',
    encrypt: 'none',
    dockLink: null,
    fields: [
      {
        label: 'Give a name',
        name: 'name',
        rules: [{ required: true, message: 'Name is required!' }],
        type: 'input',
        instructions: 'Give a name to this API.',
      },
    ],
  },
  'email-verify': {
    name: 'Email Verification',
    authType: 'key',
    encrypt: 'none',
    dockLink: null,
    fields: [
      {
        label: 'Give a name',
        name: 'name',
        rules: [{ required: true, message: 'Name is required!' }],
        type: 'input',
        instructions: 'Give a name to this API.',
      },
    ],
  },
  lookup: {
    name: 'IP Geolocation',
    authType: 'key',
    encrypt: 'none',
    dockLink: null,
    fields: [
      {
        label: 'Give a name',
        name: 'name',
        rules: [{ required: true, message: 'Name is required!' }],
        type: 'input',
        instructions: 'Give a name to this API.',
      },
    ],
  },
  mailchimp: {
    name: 'MailChimp',
    authType: 'key',
    encrypt: 'access_token',
    dockLink: 'https://nocodeapi.com/mailchimp-api',
    fields: [
      {
        label: 'Give a name',
        name: 'name',
        rules: [{ required: true, message: 'Name is required!' }],
        type: 'input',
        instructions: 'Give a name to this API.',
      },
      {
        label: 'API Key',
        name: 'access_token',
        rules: [
          {
            required: true,
            message: 'API Key is required!',
          },
        ],
        type: 'inputPwd',
        instructions: 'Go to your mailchimp account and then into Extras.',
      },
    ],
  },
  fbsdk: {
    name: 'Firebase Admin API',
    authType: 'key',
    encrypt: 'none',
    dockLink: 'https://nocodeapi.com/firebase-admin-sdk-api',
    fields: [
      {
        label: 'Give a name',
        name: 'name',
        rules: [{ required: true, message: 'Name is required!' }],
        type: 'input',
        instructions: 'Give a name to this API.',
      },
      {
        label: 'Upload service file',
        name: 'firebase-sdk',
        rules: [
          {
            required: true,
            message: 'json file is required!',
          },
        ],
        type: 'uploadFile',
        instructions: 'Download private key file and upload.',
      },
    ],
  },
  csv2json: {
    name: 'CSV to JSON API',
    formType: 'dynamicForm',
    authType: 'key2',
    encrypt: 'none',
    dockLink: 'https://nocodeapi.com/csv2json-api',
    fields: [
      {
        label: 'Give a name',
        name: 'name',
        rules: [{ required: true, message: 'Name is required!' }],
        type: 'input',
        instructions: 'Give a name to this API.',
      },
      {
        label: 'Upload CSV file',
        name: 'csv-file',
        rules: [
          {
            required: true,
            message: 'csv file is required!',
          },
        ],
        type: 'uploadFile',
      },
    ],
  },
  netlify: {
    name: 'Netlify',
    authType: 'key',
    encrypt: 'access_token',
    dockLink: 'https://nocodeapi.com/netlify-api',
    fields: [
      {
        label: 'Give a name',
        name: 'name',
        rules: [{ required: true, message: 'Name is required!' }],
        type: 'input',
        instructions: 'Give a name to this API.',
      },
      {
        label: 'Personal Access Token',
        name: 'access_token',
        rules: [
          {
            required: true,
            message: 'Personal Access Token is required!',
          },
        ],
        type: 'inputPwd',
        instructions:
          'Go here <a href="https://app.netlify.com/user/applications" target="_blank">https://app.netlify.com/user/applications</a> &  click on <b>New access token</b>',
      },
    ],
  },
  fauna: {
    name: 'Fauna',
    authType: 'key',
    encrypt: 'secret_key',
    dockLink: 'https://nocodeapi.com/fauna-db-api',
    fields: [
      {
        label: 'Give a name',
        name: 'name',
        rules: [{ required: true, message: 'Name is required!' }],
        type: 'input',
        instructions: 'Give a name to this API.',
      },
      {
        label: 'Secret Key',
        name: 'secret_key',
        rules: [
          {
            required: true,
            message: 'Secret Key is required!',
          },
        ],
        type: 'inputPwd',
        instructions: '<b>Create a database</b> -> <b>Security</b> -> <b>Generate New Key</b>.',
      },
    ],
  },
  s3: {
    name: 'AWS S3',
    authType: 'key',
    encrypt: 'secret_access_key',
    dockLink: 'https://nocodeapi.com/aws-s3-api',
    fields: [
      {
        label: 'Give a name',
        name: 'name',
        rules: [{ required: true, message: 'Name is required!' }],
        type: 'input',
        instructions: 'Give a name to this API.',
      },
      {
        label: 'Access Key ID',
        name: 'access_key_id',
        rules: [
          {
            required: true,
            message: 'Access Key ID is required!',
          },
        ],
        type: 'inputPwd',
        instructions: 'From your <b>My Security Credentials</b> tab',
      },
      {
        label: 'Secret Access Key',
        name: 'secret_access_key',
        rules: [
          {
            required: true,
            message: 'Secret Access Key is required!',
          },
        ],
        type: 'inputPwd',
        instructions: 'From your <b>My Security Credentials</b> tab',
      },
    ],
  },
  zohomail: {
    name: 'Zoho Mail',
    authType: 'oauth',
    formType: 'dynamicForm',
    authEndpoint: `${process.env.GATSBY_BASE_URL}/auth/zohomail`,
    encrypt: 'access_token',
    authKey: 'zohoMailEnable',
    dockLink: 'https://nocodeapi.com/zoho-mail-api',
    fields: [
      {
        label: 'Give a name',
        name: 'name',
        rules: [{ required: true, message: 'Name is required!' }],
        type: 'input',
        instructions: 'Give a name to this API.',
      },
    ],
  },
  zohosheet: {
    name: 'Zoho Sheet',
    authType: 'oauth',
    authEndpoint: `${process.env.GATSBY_BASE_URL}/auth/zohosheet`,
    encrypt: 'access_token',
    authKey: 'zohoSheetEnable',
    dockLink: 'https://nocodeapi.com/zoho-sheet-api',
    fields: [
      {
        label: 'Give a name',
        name: 'name',
        rules: [{ required: true, message: 'Name is required!' }],
        type: 'input',
        instructions: 'Give a name to this API.',
      },
      {
        label: 'Sheet Id',
        name: 'sheet_id',
        rules: [
          {
            required: true,
            message: 'Sheet Id is required!',
          },
        ],
        type: 'input',
        instructions:
          'Sheet ID Example: https://sheet.zoho.com/sheet/open/<b>bz2aaea67a1ff1da84a0cb7744d80055a9b57</b>. Bold part is the sheet id',
      },
    ],
  },
  medium: {
    name: 'Medium',
    authType: 'key',
    encrypt: 'none',
    dockLink: 'https://nocodeapi.com/video/access-medium-feed-as-json-api-api-medium',
    fields: [
      {
        label: 'Give a name',
        name: 'name',
        rules: [{ required: true, message: 'Name is required!' }],
        type: 'input',
        instructions: 'Give a name to this API.',
      },
      {
        label: 'Medium Feed URL',
        name: 'xml_url',
        rules: [
          {
            type: 'url',
            required: true,
            message: 'Feed URL is required!',
          },
        ],
        type: 'input',
        instructions:
          'This is an examople <a href="https://medium.com/feed/@mddanishyusuf" target="_blank" rel="noopener noreferrer">https://medium.com/feed/@mddanishyusuf</a>',
      },
    ],
  },
  smartsheet: {
    name: 'Smartsheet',
    authType: 'key',
    encrypt: 'access_token',
    dockLink: 'https://nocodeapi.com/smartsheet-api',
    fields: [
      {
        label: 'Give a name',
        name: 'name',
        rules: [{ required: true, message: 'Name is required!' }],
        type: 'input',
        instructions: 'Give a name to this API.',
      },
      {
        label: 'API Access token',
        name: 'access_token',
        rules: [
          {
            required: true,
            message: 'API Access token is required!',
          },
        ],
        type: 'inputPwd',
        instructions:
          'Go to Personal Apps & integration -> API Access -> Generate new access token',
      },
    ],
  },
  ow: {
    name: 'Open Weather',
    authType: 'key',
    encrypt: 'api_key',
    dockLink: 'https://nocodeapi.com/video/setup-open-weather-api-without-coding-api-weather',
    fields: [
      {
        label: 'Give a name',
        name: 'name',
        rules: [{ required: true, message: 'Name is required!' }],
        type: 'input',
        instructions: 'Give a name to this API.',
      },
      {
        label: 'API key',
        name: 'api_key',
        rules: [
          {
            required: true,
            message: 'API key is required!',
          },
        ],
        type: 'inputPwd',
        instructions:
          'Here you can find your Open Weather API key <a href="https://home.openweathermap.org/api_keys" target="_blank" rel="noopener noreferrer">https://home.openweathermap.org/api_keys</a>',
      },
    ],
  },
  slack: {
    name: 'Slack',
    authType: 'key2',
    encrypt: 'oauth_token',
    formType: 'dynamicForm',
    authKey: 'slackEnable',
    dockLink: 'https://nocodeapi.com/slack-api',
  },
  spotify: {
    name: 'Spotify',
    authType: 'oauth',
    authKey: 'spotifyEnable',
    authEndpoint: `${process.env.GATSBY_BASE_URL}/auth/spotify`,
    encrypt: 'access_token',
    dockLink: null,
    fields: [
      {
        label: 'Give a name',
        name: 'name',
        rules: [{ required: true, message: 'Name is required!' }],
        type: 'input',
        instructions: 'Give a name to this API.',
      },
    ],
  },
  notion: {
    name: 'Notion',
    authType: 'oauth',
    authKey: 'notionEnable',
    authEndpoint: `${process.env.GATSBY_BASE_URL}/auth/notion`,
    encrypt: 'access_token',
    dockLink: null,
    fields: [
      {
        label: 'Give a name',
        name: 'name',
        rules: [{ required: true, message: 'Name is required!' }],
        type: 'input',
        instructions: 'Give a name to this API.',
      },
    ],
  },
  unsplash: {
    name: 'Unsplash',
    authType: 'key',
    encrypt: 'access_token',
    dockLink: 'https://nocodeapi.com/unsplash-api',
    fields: [
      {
        label: 'Give a name',
        name: 'name',
        rules: [{ required: true, message: 'Name is required!' }],
        type: 'input',
        instructions: 'Give a name to this API.',
      },
      {
        label: 'Access Key',
        name: 'access_token',
        rules: [
          {
            required: true,
            message: 'Access Key is required!',
          },
        ],
        type: 'inputPwd',
        instructions:
          '1. Go to <a href="https://unsplash.com/oauth/applications" target="_blank">https://unsplash.com/oauth/applications</a> and Create new Application. <br/>2. Copy Access Key',
      },
    ],
  },
  sendgrid: {
    name: 'Sendgrid',
    authType: 'key',
    encrypt: 'access_token',
    dockLink: null,
    fields: [
      {
        label: 'Give a name',
        name: 'name',
        rules: [{ required: true, message: 'Name is required!' }],
        type: 'input',
        instructions: 'Give a name to this API.',
      },
      {
        label: 'Access Key',
        name: 'access_token',
        rules: [
          {
            required: true,
            message: 'Access Key is required!',
          },
        ],
        type: 'inputPwd',
        instructions:
          '1. Go to <a href="https://app.sendgrid.com/settings/api_keys" target="_blank">https://app.sendgrid.com/settings/api_keys</a> and Create API Key. <br/>2. Copy API Key',
      },
    ],
  },
  gumroad: {
    name: 'Gumroad',
    authType: 'key',
    encrypt: 'access_token',
    dockLink: 'https://nocodeapi.com/gumroad-api',
    fields: [
      {
        label: 'Give a name',
        name: 'name',
        rules: [{ required: true, message: 'Name is required!' }],
        type: 'input',
        instructions: 'Give a name to this API.',
      },
      {
        label: 'Access Token',
        name: 'access_token',
        rules: [
          {
            required: true,
            message: 'Access Token is required!',
          },
        ],
        type: 'inputPwd',
        instructions:
          '1. Go to <a href="https://gumroad.com/oauth/applications" target="_blank">https://gumroad.com/oauth/applications</a> and Fill the form Application name & redirect URI(just put any website name) <br/>2. Create application 3. Click on Generate access token. 4. Copy token',
      },
    ],
  },
  telegram: {
    name: 'Telegram',
    authType: 'key2',
    formType: 'dynamicForm',
    encrypt: 'bot_key',
    dockLink:
      'https://nocodeapi.com/video/setup-telegram-api-without-coding-telegram-automation-api',
    fields: [
      {
        label: 'Give a name',
        name: 'name',
        rules: [{ required: true, message: 'Name is required!' }],
        type: 'input',
        instructions: 'Give a name to this API.',
      },
      {
        label: 'Bot token key',
        name: 'bot_key',
        rules: [
          {
            required: true,
            message: 'Bot key is required!',
          },
        ],
        type: 'inputPwd',
        instructions:
          'To get bot token, you have to make a telegram bot with <b>@botfather</b> tool',
      },
      {
        label: 'Chat Id',
        name: 'chat_id',
        rules: [
          {
            required: true,
            message: 'Chat Id is required',
          },
        ],
        type: 'input',
        instructions:
          'Send some test message in channel and then use this API to get chat Id <b>https://api.telegram.org/bot_bot_key_/getUpdates</b> by replacing _bot_key_',
      },
    ],
  },
  vimeo: {
    name: 'Vimeo',
    authType: 'key',
    encrypt: 'access_token',
    dockLink: 'https://nocodeapi.com/vimeo-api',
    fields: [
      {
        label: 'Give a name',
        name: 'name',
        rules: [{ required: true, message: 'Name is required!' }],
        type: 'input',
        instructions: 'Give a name to this API.',
      },
      {
        label: 'Access Token',
        name: 'access_token',
        rules: [
          {
            required: true,
            message: 'Access Token is required!',
          },
        ],
        type: 'inputPwd',
        instructions:
          '1. Go to <a href="https://developer.vimeo.com/apps" target="_blank">https://developer.vimeo.com/apps</a> and Create an app. <br/>2. Generate Access Token',
      },
    ],
  },
  twitter: {
    name: 'Twitter',
    authType: 'key',
    encrypt: 'access_token_secret',
    dockLink: 'https://nocodeapi.com/video/setup-twitter-api-without-coding-api-twitter',
    fields: [
      {
        label: 'Give a name',
        name: 'name',
        rules: [{ required: true, message: 'Name is required!' }],
        type: 'input',
        instructions: 'Give a name to this API.',
      },
      {
        label: 'API Key',
        name: 'consumer_key',
        rules: [
          {
            required: true,
            message: 'API Key is required!',
          },
        ],
        type: 'inputPwd',
        instructions:
          'You can find Consumer Key here <a href="https://developer.twitter.com/en/portal/dashboard" target="_blank" rel="noopener noreferrer">https://developer.twitter.com/en/portal/dashboard</a>',
      },
      {
        label: 'API Secret Key',
        name: 'consumer_secret',
        rules: [
          {
            required: true,
            message: 'API Secret Key is required!',
          },
        ],
        type: 'inputPwd',
        instructions:
          'You can find Consumer Secret Key here <a href="https://developer.twitter.com/en/portal/dashboard" target="_blank" rel="noopener noreferrer">https://developer.twitter.com/en/portal/dashboard</a>',
      },
      {
        label: 'Access Token',
        name: 'access_token',
        rules: [
          {
            required: true,
            message: 'Access Token is required!',
          },
        ],
        type: 'inputPwd',
        instructions:
          'You can find Access Token here <a href="https://developer.twitter.com/en/portal/dashboard" target="_blank" rel="noopener noreferrer">https://developer.twitter.com/en/portal/dashboard</a>',
      },
      {
        label: 'Access Token Secret',
        name: 'access_token_secret',
        rules: [
          {
            required: true,
            message: 'Access Token Secret is required!',
          },
        ],
        type: 'inputPwd',
        instructions:
          'You can find Access Token Secret here <a href="https://developer.twitter.com/en/portal/dashboard" target="_blank" rel="noopener noreferrer">https://developer.twitter.com/en/portal/dashboard</a>',
      },
      {
        label: 'Bearer token',
        name: 'bearer_token',
        rules: [
          {
            required: true,
            message: 'Bearer token is required!',
          },
        ],
        type: 'inputPwd',
        instructions:
          'You can find Access Token Secret here <a href="https://developer.twitter.com/en/portal/dashboard" target="_blank" rel="noopener noreferrer">https://developer.twitter.com/en/portal/dashboard</a>',
      },
    ],
  },
  uptime: {
    name: 'Uptime Robot',
    authType: 'key',
    encrypt: 'main_api_key',
    dockLink:
      'https://nocodeapi.com/video/uptime-robot-api-and-build-custom-status-page-for-your-website-api',
    fields: [
      {
        label: 'Give a name',
        name: 'name',
        rules: [{ required: true, message: 'Name is required!' }],
        type: 'input',
        instructions: 'Give a name to this API.',
      },
      {
        label: 'Main API Key',
        name: 'main_api_key',
        rules: [
          {
            required: true,
            message: 'Main API Key is required!',
          },
        ],
        type: 'inputPwd',
        instructions:
          'Here you can find your Main API key here <a href="https://uptimerobot.com/dashboard#mySettings" target="_blank" rel="noopener noreferrer">API Settings</a>',
      },
    ],
  },
  mondaydotcom: {
    name: 'Monday.com',
    authType: 'key',
    encrypt: 'api_token',
    dockLink: 'https://nocodeapi.com/monday-dot-com-api',
    fields: [
      {
        label: 'Give a name',
        name: 'name',
        rules: [{ required: true, message: 'Name is required!' }],
        type: 'input',
        instructions: 'Give a name to this API.',
      },
      {
        label: 'Personal API Token',
        name: 'api_token',
        rules: [
          {
            required: true,
            message: 'Personal API Token is required!',
          },
        ],
        type: 'inputPwd',
        instructions: 'Monday.com personal api token from your admin settings.',
      },
    ],
  },
  // hubspot: {
  //     name: 'Hubspot',
  //     authType: 'key',
  //     encrypt: 'api_token',
  //     dockLink: 'https://nocodeapi.com/hubspot-api',
  //     fields: [
  //         {
  //             label: 'Give a name',
  //             name: 'name',
  //             rules: [{ required: true, message: 'Name is required!' }],
  //             type: 'input',
  //             instructions: 'Give a name to this API.',
  //         },
  //         {
  //             label: 'Personal API Token',
  //             name: 'api_token',
  //             rules: [
  //                 {
  //                     required: true,
  //                     message: 'API key is required!',
  //                 },
  //             ],
  //             type: 'inputPwd',
  //             instructions:
  //                 'In your HubSpot account, click the <b>settings icon</b> in the main navigation bar. In the left sidebar menu, navigate to <b>Integrations</b> > <b>API key</b>',
  //         },
  //     ],
  // },
  fbpages: {
    name: 'Facebook Pages',
    authType: 'key',
    encrypt: 'long_lived_token',
    dockLink: 'https://nocodeapi.com/facebook-pages-api',
    fields: [
      {
        label: 'Give a name',
        name: 'name',
        rules: [{ required: true, message: 'Name is required!' }],
        type: 'input',
        instructions: 'Give a name to this API.',
      },
      {
        label: 'Long Lived Token',
        name: 'long_lived_token',
        rules: [
          {
            required: true,
            message: 'Personal Long Lived Token is required!',
          },
        ],
        type: 'inputPwd',
        instructions:
          'Follow this link <a href="https://nocodeapi.com/tool/facebook-pages-long-lived-token" target="_blank">https://nocodeapi.com/tool/facebook-pages-long-lived-token</a> to get Long Lived Token.',
      },
      {
        label: 'Facebook Page username or ID',
        name: 'page_id',
        rules: [
          {
            required: true,
            message: 'username or ID is required!',
          },
        ],
        type: 'input',
        instructions: 'Facebook page username or ID',
      },
    ],
  },
  supabase: {
    name: 'Supabase',
    authType: 'key',
    encrypt: 'supabaseKey',
    dockLink: 'https://nocodeapi.com/supabase-api',
    fields: [
      {
        label: 'Give a name',
        name: 'name',
        rules: [{ required: true, message: 'Name is required!' }],
        type: 'input',
        instructions: 'Give a name to this API.',
      },
      {
        label: 'Supabase URL',
        name: 'supabaseUrl',
        rules: [
          {
            required: true,
            message: 'Supabase URL is required!',
          },
        ],
        type: 'input',
        instructions: 'Supabase root url from settings.',
      },
      {
        label: 'Supabase Key',
        name: 'supabaseKey',
        rules: [
          {
            required: true,
            message: 'Supabase Key is required!',
          },
        ],
        type: 'inputPwd',
        instructions: 'Supabase API Keys(anon public key) from settings.',
      },
    ],
  },
  webflow: {
    name: 'Webflow',
    authType: 'key2',
    formType: 'dynamicForm',
    encrypt: 'api_access',
    dockLink:
      'https://nocodeapi.com/video/convert-webflow-cms-collection-into-json-api-with-no-code',
    fields: [
      {
        label: 'Give a name',
        name: 'name',
        rules: [{ required: true, message: 'Name is required!' }],
        type: 'input',
        instructions: 'Give a name to this API.',
      },
      {
        label: 'API Access Token',
        name: 'api_access',
        rules: [
          {
            required: true,
            message: 'API Access Token is required!',
          },
        ],
        type: 'inputPwd',
        instructions:
          'Go to dashboard -> click on three dots icon -> settings -> Integrations -> Generate API Access. Note: Now supports only API V2',
      },
    ],
  },
  xml_to_json: {
    name: 'XML JSON Parser',
    authType: 'key',
    encrypt: 'none',
    dockLink: 'https://nocodeapi.com/video/convert-xml-url-to-json-api-without-coding-xml-json',
    fields: [
      {
        label: 'Give a name',
        name: 'name',
        rules: [{ required: true, message: 'Name is required!' }],
        type: 'input',
        instructions: 'Give a name to this API.',
      },
    ],
  },
  yt: {
    name: 'YouTube',
    authType: 'oauth',
    authKey: 'ytEnable',
    authEndpoint: `${process.env.GATSBY_BASE_URL}/auth/google/yt`,
    encrypt: 'access_token',
    dockLink: 'https://nocodeapi.com/video/setup-you-tube-data-api-without-code-youtube-data-api',
    fields: [
      {
        label: 'Give a name',
        name: 'name',
        rules: [{ required: true, message: 'Name is required!' }],
        type: 'input',
        instructions: 'Give a name to this API.',
      },
    ],
  },
  pocket: {
    name: 'Pocket',
    authType: 'oauth',
    authKey: 'pocketEnable',
    authEndpoint: `${process.env.GATSBY_BASE_URL}/auth/pocket`,
    encrypt: 'access_token',
    dockLink: null,
    fields: [
      {
        label: 'Give a name',
        name: 'name',
        rules: [{ required: true, message: 'Name is required!' }],
        type: 'input',
        instructions: 'Give a name to this API.',
      },
    ],
  },
  wordpress: {
    name: 'WordPress',
    authType: 'key',
    encrypt: 'none',
    dockLink:
      'https://nocodeapi.com/video/setup-word-press-json-api-with-no-code-without-server-and-code',
    fields: [
      {
        label: 'Give a name',
        name: 'name',
        rules: [{ required: true, message: 'Name is required!' }],
        type: 'input',
        instructions: 'Give a name to this API.',
      },
      {
        label: 'WordPress WP JSON Endpoint',
        name: 'web_url',
        rules: [
          {
            required: true,
            message: 'Wordpress Website URL is required!',
          },
        ],
        type: 'input',
        instructions: 'WP JSON URL ex: http://src.wordpress-develop.dev/wp-json',
      },
    ],
  },
  pipedrive: {
    name: 'Pipedrive',
    authType: 'key',
    encrypt: 'api_token',
    dockLink:
      'https://nocodeapi.com/video/setup-pipedrive-api-without-code-and-server-with-no-code-api',
    fields: [
      {
        label: 'Give a name',
        name: 'name',
        rules: [{ required: true, message: 'Name is required!' }],
        type: 'input',
        instructions: 'Give a name to this API.',
      },
      {
        label: 'Company Domain Name',
        name: 'company_domain',
        rules: [
          {
            required: true,
            message: 'Company Domain Name is required!',
          },
        ],
        type: 'input',
        instructions: '"nocodeapi" is the domain name in this url https://nocodeapi.pipedrive.com/',
      },
      {
        label: 'Personal API token',
        name: 'api_token',
        rules: [
          {
            required: true,
            message: 'Personal API token is required!',
          },
        ],
        type: 'inputPwd',
        instructions:
          'You can get your Pipedrive Personal API key as guided here https://pipedrive.readme.io/docs/how-to-find-the-api-token',
      },
    ],
  },
  mailgun: {
    name: 'Mailgun',
    authType: 'key',
    encrypt: 'private_api_key',
    dockLink: null,
    fields: [
      {
        label: 'Give a name',
        name: 'name',
        rules: [{ required: true, message: 'Name is required!' }],
        type: 'input',
        instructions: 'Give a name to this API.',
      },
      {
        label: 'Private API Key',
        name: 'private_api_key',
        rules: [
          {
            required: true,
            message: 'Company Domain Name is required!',
          },
        ],
        type: 'inputPwd',
        instructions:
          'You can get your <b>Private API Key</b> here <a href="https://app.mailgun.com/app/account/security/api_keys" target="_blank">https://app.mailgun.com/app/account/security/api_keys</a>',
      },
      {
        label: 'Public Validation key',
        name: 'public_validation_key',
        rules: [
          {
            required: true,
            message: 'Personal API token is required!',
          },
        ],
        type: 'inputPwd',
        instructions:
          'You can get your <b>Public Validation Key</b> here <a href="https://app.mailgun.com/app/account/security/api_keys" target="_blank">https://app.mailgun.com/app/account/security/api_keys</a>',
      },
      {
        label: 'Domain Name',
        name: 'domain_name',
        rules: [
          {
            required: true,
            message: 'Personal API token is required!',
          },
        ],
        type: 'input',
        instructions:
          'You can get your <b>Domain Lists</b> here <a href="https://app.mailgun.com/app/sending/domains" target="_blank">https://app.mailgun.com/app/sending/domains</a>',
      },
    ],
  },
  stripe: {
    name: 'stripe',
    encrypt: 'account_secret_key',
    authType: 'key',
    dockLink: null,
    fields: [
      {
        label: 'Give a name',
        name: 'name',
        rules: [{ required: true, message: 'Name is required!' }],
        type: 'input',
        instructions: 'Give a name to this API.',
      },
      {
        label: 'Account Secret Key',
        name: 'account_secret_key',
        rules: [
          {
            required: true,
            message: 'Secret key of your Stripe account!',
          },
        ],
        type: 'inputPwd',
        instructions: `You can find here <a href="https://dashboard.stripe.com/account/apikeys" target="_blank">https://dashboard.stripe.com/account/apikeys</a>. Don't worry, your all keys are encrypted.`,
      },
    ],
  },
  gTrends: {
    name: 'Google Trends',
    authType: 'key',
    encrypt: 'none',
    dockLink: 'https://nocodeapi.com',
    fields: [
      {
        label: 'Give a name',
        name: 'name',
        rules: [{ required: true, message: 'Name is required!' }],
        type: 'input',
        instructions: 'Give a name to this API.',
      },
    ],
  },
  nForms: {
    name: 'NoCode Forms',
    authType: 'key',
    encrypt: 'none',
    dockLink: 'https://nocodeapi.com/docs/nocode-forms',
    fields: [
      {
        label: 'Give a name',
        name: 'name',
        rules: [{ required: true, message: 'Name is required!' }],
        type: 'input',
        instructions: 'Give a name to this API.',
      },
    ],
  },
  databaseMongoApi: {
    name: 'Mongo Database API with NoCode',
    authType: 'key',
    encrypt: 'database_connection',
    dockLink: 'https://nocodeapi.com/database-mongo',
    fields: [
      {
        label: 'Provide a name',
        name: 'name',
        rules: [{ required: true, message: 'Name is required!' }],
        type: 'input',
        instructions: 'Give a name to this API.',
      },
      {
        label: 'Database Connection String',
        name: 'database_connection',
        rules: [
          {
            required: true,
            message: 'Enter your database connection string',
          },
        ],
        type: 'input',
        instructions:
          'Enter your database connection string. Do not worry - we encrypt everything!',
      },
      {
        label: 'Database refresh time',
        name: 'database_refresh_interval',
        rules: [
          {
            required: true,
            message: 'Enter time to refresh database in hour',
          },
        ],
        type: 'input',
        instructions: 'Enter your time to reload in hours eg, 4 or 6, default is 24 ',
      },
    ],
  },
  databasePostgresApi: {
    name: 'Postgres Database API with NoCode',
    authType: 'key',
    encrypt: 'database_password',
    dockLink: 'https://nocodeapi.com/database-postgres',
    fields: [
      {
        label: 'Provide a name',
        name: 'name',
        rules: [{ required: true, message: 'Name is required!' }],
        type: 'input',
        instructions: 'Give a name to this API.',
      },
      {
        label: 'Database Name',
        name: 'database_name',
        rules: [
          {
            required: true,
            message: 'Name of database',
          },
        ],
        type: 'input',
        instructions: 'Enter your database name',
      },
      {
        label: 'Host',
        name: 'database_host',
        rules: [
          {
            required: true,
            message: 'Host of database',
          },
        ],
        type: 'input',
        instructions: 'Enter your database host',
      },
      {
        label: 'Port',
        name: 'database_port',
        rules: [
          {
            required: true,
            message: 'Port used in database',
          },
        ],
        type: 'input',
        instructions: 'Enter your database port',
      },
      {
        label: 'Username',
        name: 'database_username',
        rules: [
          {
            required: true,
            message: 'Username is required!',
          },
        ],
        type: 'input',
        instructions: 'Enter your database username',
      },
      {
        label: 'Password',
        name: 'database_password',
        rules: [
          {
            required: true,
            message: 'Enter your database password',
          },
        ],
        type: 'inputPwd',
        instructions: 'Enter your database password. Do not worry - we encrypt everything!',
      },
      {
        label: 'Database refresh time',
        name: 'database_refresh_interval',
        rules: [
          {
            required: true,
            message: 'Enter time to refresh database in hour',
          },
        ],
        type: 'input',
        instructions: 'Enter your time to reload in hours eg, 4 or 6, default is 24 ',
      },
    ],
  },
  enrich: {
    name: 'Enrich Email',
    authType: 'key',
    encrypt: 'none',
    dockLink: 'https://nocodeapi.com/docs/enrich',
    fields: [
      {
        label: 'Give a name',
        name: 'email',
        rules: [{ required: true, message: 'Name is required!' }],
        type: 'input',
        instructions: 'Give name to this API.',
      },
    ],
  },
  chatgpt: {
    name: 'ChatGPT',
    authType: 'key',
    encrypt: 'none',
    dockLink: 'https://nocodeapi.com/docs/chatgpt',
    fields: [
      {
        label: 'Give a name',
        name: 'name',
        rules: [{ required: true, message: 'Name is required!' }],
        type: 'input',
        instructions: 'Give a name to this API.',
      },
    ],
  },
  claude: {
    name: 'Claude AI',
    authType: 'key',
    encrypt: 'none',
    dockLink: 'https://nocodeapi.com/docs/claude-ai',
    fields: [
      {
        label: 'Give a name',
        name: 'name',
        rules: [{ required: true, message: 'Name is required!' }],
        type: 'input',
        instructions: 'Give a name to this API.',
      },
      {
        label: 'API key',
        name: 'api_key',
        rules: [
          {
            required: true,
            message: 'API Key is required!',
          },
        ],
        type: 'inputPwd',
        instructions:
            'Your API Key ',
      },
    ],
  },
  quickbooks: {
    name: 'QuickBooks',
    authType: 'oauth',
    authKey: 'quickBooksEnable',
    authEndpoint: `${process.env.GATSBY_BASE_URL}/auth/quickbooks`,
    encrypt: 'access_token',
    dockLink: 'https://nocodeapi.com/docs/quickbooks',
    fields: [
      {
        label: 'Give a name',
        name: 'name',
        rules: [{ required: true, message: 'Name is required!' }],
        type: 'input',
        instructions: 'Give a name to this API.',
      },
    ],
  },
};
